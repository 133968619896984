import Image from 'next/image';

import MetaOgHead from '../components/MetaOgHead';
import Main from '../containers/Main';

export default function Home() {
  return (
    <>
      <Main>
        <MetaOgHead
          title="Makara is now Crypto Investing by Betterment"
          desc="Expert-built crypto portfolios designed for long-term investing."
          url="/"
          imageUrl="/images/makara-og.png"
        />

        <section className="relative z-10">
          <div className="md:pl-12 lg:mt-20 md:absolute md:z-20 md:top-0">
            <div className="flex items-start justify-start w-full h-full bg-[#FDFCD0] md:bg-transparent xl:bg-transparent md:pb-8">
              <h2 className="">
                <span className="block ml-8 lg:ml-16 mt-12 lg:mt-20 lg:mt-0 mb-1 text-2xl lg:text-3xl font-bold text-neutral-900">
                  Makara is now
                </span>
                <span className="block ml-8 lg:ml-16 text-3xl lg:text-4xl font-bold text-neutral-900">
                  Crypto Investing by
                </span>
                <span className="ml-8 lg:ml-16 text-3xl lg:text-4xl font-bold text-neutral-900 mark-gold-350">
                  Betterment
                </span>
                <span className="block mx-8 mt-16 mb-6 lg:ml-16 md:w-96 text-lg md:text-2xl font-bold text-neutral-900">
                  Expert-built crypto portfolios designed for long-term
                  investing
                </span>
                <a
                  href="https://www.betterment.com/crypto"
                  className="ml-8 underline lg:ml-16 text-lg underline-offset-4"
                >
                  Learn more
                </a>
              </h2>
            </div>
          </div>

          <div className="bg-[#FDFCD0]">
            <div className="relative h-96 md:h-[36rem] md:ml-[30%]">
              <Image
                src="/images/crypto-rider-square.png"
                layout="fill"
                alt="Other sites let you buy crypto. We help you invest in it. Tell us your goals and interests. We’ll personalize your portfolio."
                objectFit="contain"
                className="w-3/5 h-auto"
                quality={100}
                priority
                unoptimized={
                  !(process.env.NEXT_PUBLIC_VERCEL_ENV === 'production')
                }
              />
            </div>
          </div>
        </section>
      </Main>
    </>
  );
}
